import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-row align-items-start" }
const _hoisted_2 = { class: "d-flex d-flex-row list-inline list-unstyled justify-content-between" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tab.id,
          class: _normalizeClass(["border-bottom", {active: tab.active, disabled: tab.disabled}]),
          onClick: ($event: any) => ($setup.activateTab(tab))
        }, _toDisplayString(tab.name), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}