import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "complete" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "d-flex flex-column align-items-start" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "d-flex flex-column align-items-start fw-bold" }
const _hoisted_6 = { class: "d-flex flex-column align-items-end" }
const _hoisted_7 = { class: "d-flex flex-column align-items-end" }
const _hoisted_8 = { class: "d-flex flex-column align-items-end" }
const _hoisted_9 = { class: "d-flex flex-column align-items-end fw-bold" }
const _hoisted_10 = { class: "d-flex flex-column align-items-end position-relative" }
const _hoisted_11 = { class: "item d-flex flex-row justify-content-between align-items-center" }
const _hoisted_12 = { class: "image-box w-25" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "info-box w-75 d-flex flex-column" }
const _hoisted_15 = { class: "d-flex fw-bold text-start" }
const _hoisted_16 = { class: "text-start mt-1" }
const _hoisted_17 = { class: "d-flex flex-row justify-content-between mt-3" }
const _hoisted_18 = { class: "fw-bold" }
const _hoisted_19 = { class: "position-relative price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarItem = _resolveComponent("BarItem")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_LogoDivider = _resolveComponent("LogoDivider")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BarItem, {
      class: "w-100",
      show: ""
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "red" }, " Bedankt voor uw bestelling! ", -1)),
          _createElementVNode("h5", null, "Bestelnummer: " + _toDisplayString($props.order.no), 1),
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-start" }, [
            _createTextVNode(" Wij hebben uw order ontvangen. "),
            _createElementVNode("br"),
            _createTextVNode(" U ontvangt per e-mail een bevestiging van uw order. ")
          ], -1))
        ])
      ]),
      items: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.order.saleslines, (saleLine) => {
          return (_openBlock(), _createBlock(_component_BarItem, {
            id: "complete-bar",
            key: saleLine,
            class: "d-none d-lg-block",
            divider: "top",
            design: "high-bar no-margin"
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  style: {"max-height":"80px","margin":"0 10px"},
                  src: saleLine.item?.image_small,
                  alt: "wine"
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, _toDisplayString(saleLine.item?.name), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(saleLine.item?.year_of_harvest !== '0' ?  saleLine.item?.year_of_harvest : 'N.M.'), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(saleLine.item?.volume) + " L ", 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(saleLine.item?.units_per_parcel) + " Fl. ", 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(saleLine.quantity) + "x ", 1),
              _createElementVNode("div", _hoisted_10, [
                _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString(saleLine.amount)) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.order.saleslines, (saleLine) => {
          return (_openBlock(), _createElementBlock("div", {
            key: saleLine,
            class: "sale-item d-block d-lg-none"
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "divider" }, null, -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("img", {
                  style: {"height":"116px"},
                  src: saleLine.item?.image_small,
                  alt: "wine"
                }, null, 8, _hoisted_13)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(saleLine.item?.name), 1),
                _createElementVNode("div", _hoisted_16, _toDisplayString(saleLine.item?.year_of_harvest !== '0' ?  saleLine.item?.year_of_harvest : 'N.M.') + " | " + _toDisplayString(saleLine.item?.volume) + " L | " + _toDisplayString(saleLine.item?.units_per_parcel) + " Fl. ", 1),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(saleLine.quantity) + " flessen ", 1),
                  _createElementVNode("div", _hoisted_19, [
                    _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString(saleLine.amount)) + " ", 1),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
                  ])
                ])
              ])
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_PageFooter, {
      class: "d-none d-md-flex mb-5",
      footer: "Wilt u contact met ons opnemen?",
      onButtonClick: $setup.openContact
    }, null, 8, ["onButtonClick"]),
    _createVNode(_component_LogoDivider, {
      class: "d-block d-md-none",
      margin: "50px"
    }),
    _createVNode(_component_Button, {
      class: "d-inline d-md-none",
      text: "Assortiment",
      design: "primary",
      onButtonClick: $setup.navigateToAssortiment
    }, null, 8, ["onButtonClick"])
  ]))
}