import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "orders"
}
const _hoisted_2 = { class: "order-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSwitch = _resolveComponent("ButtonSwitch")!
  const _component_OrderItem = _resolveComponent("OrderItem")!
  const _component_CardButton = _resolveComponent("CardButton")!
  const _component_TwoColPage = _resolveComponent("TwoColPage")!

  return (_openBlock(), _createBlock(_component_TwoColPage, {
    title: "Bestellingen",
    footer: $setup.width >= 992 ? 'Heeft u vragen?' : null,
    onFooterButtonClick: $setup.openContactUrl
  }, {
    main: _withCtx(() => [
      _createVNode(_component_ButtonSwitch, {
        id: "switch",
        tabs: $setup.tabs,
        onActivate: $setup.activateTab
      }, null, 8, ["tabs", "onActivate"]),
      ($setup.tabs[0].active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groupedOrders, (order, key) => {
                return (_openBlock(), _createElementBlock("div", { key: order }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groupedOrders[key], (order, index) => {
                    return (_openBlock(), _createBlock(_component_OrderItem, {
                      key: order.id,
                      order: order,
                      showDate: index === 0
                    }, null, 8, ["order", "showDate"]))
                  }), 128))
                ]))
              }), 128))
            ]),
            (!$setup.orders || $setup.orders.length === 0)
              ? (_openBlock(), _createBlock(_component_CardButton, {
                  key: 0,
                  class: "my-3",
                  text: "U heeft nog geen bestellingen. Bestellingen worden hier getoond zodra u uw eerste bestelling heeft gedaan.",
                  buttonText: "Naar assortiment",
                  onButtonClick: $setup.navigateToAssortiment
                }, null, 8, ["onButtonClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["footer", "onFooterButtonClick"]))
}