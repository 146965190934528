<template>
    <div class="d-flex flex-column justify-content-center align-items-center footer">
        <img src="/images/grapes.svg">
        <h2 v-if="footer" :class="font">
            {{ footer }}
        </h2>
        <div v-if="box" class="box">
            {{ box }}
        </div>
        <Button :text="button.text" design="primary" @buttonClick="$emit('buttonClick')">
        </Button>
    </div>
</template>

<script type="ts">
import Button from '@/shared/components/Button.vue';

export default {
    props: {
        footer: {
            type: String,
        },
        font: {
            type: String,
            default: 'martel',
        },
        button: {
            type: Object,
            default: () => ({
                text: 'Neem contact op',
            }),
        },

        box: {
            type: String,
        },
    },
    components: {
        Button,
    },
    setup() {
        return {
            //
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

.footer {
    img {
        margin: 70px 0 65px 0;
    }

    h2 {
        font-size: 2.5rem;
        line-height: 3.125rem;

        &.martel {
            font-family: Martel, serif;
            max-width: 400px;
        }

        &.dinpro {
            font-family: "DINPro", sans-serif;
            font-size: 30px;
        }
    }

    .box {
        background: $white;
        max-width: 460px;
        padding: 16px 64px 32px;
        margin-bottom: -30px;
        margin-top: 16px;
    }

    Button {
        max-width: 9.5rem;
        font-size: 1rem;
        padding: 0;
    }
}
</style>
