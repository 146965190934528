
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import CloseIcon from '@/assets/icons/close-outline.svg';
import HeartOutlineIcon from '@/assets/icons/heart-outline.svg';
import HeartIcon from '@/assets/icons/heart.svg';
import { debounce } from '@/helpers/Debounce';
import moneyString from '@/helpers/moneyString';
import BarItem from '@/shared/components/BarItem.vue';
import CounterButton from '@/shared/components/CounterButton.vue';
import Tooltip from '@/shared/components/Tooltip.vue';
import { CartItem } from '@/shared/models/CartItem';

export default {
    props: {
        cartItem: {
            type: CartItem,
        },
        divider: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        Tooltip,
        BarItem,
        CounterButton,
        CloseIcon,
    },
    setup(props: any, context: any) {
        const store = useStore();
        const money = moneyString();
        const itemRef = ref(props.cartItem);
        const boxCount = computed(() => Math.floor(itemRef.value.quantity / itemRef.value.item.units_per_parcel));
        const bottleCount = computed(() => itemRef.value.quantity);

        function updateCart() {
            store.dispatch('CART_UPDATE', { item_no: itemRef.value.item.id, quantity: itemRef.value.quantity })
                .then((res) => {
                    context.emit('updateCart', res.data);
                });
        }

        const debouncer = debounce(() => {
            updateCart();
        }, 1000);

        function addBoxToCart(amount: any, item: any) {
            item.quantity += amount * item.item.units_per_parcel;

            if (item.quantity <= 0) {
                context.emit('removeItem', item);
                return;
            }

            itemRef.value.quantity = item.quantity;

            debouncer();
        }

        function addBottleToCart(amount: any, item: any) {
            if (item.quantity > 0) {
                item.quantity += amount;
            }

            if (item.quantity <= 0) {
                context.emit('removeItem', item);
                return;
            }

            itemRef.value.quantity = item.quantity;

            debouncer();
        }

        function favorite() {
            itemRef.value.item.is_favorited = !itemRef.value.item.is_favorited;
            itemRef.value.item.wine.is_favorited = itemRef.value.item.is_favorited;
            store.dispatch('WINE_FAVORITE', itemRef.value.item.wine);
        }

        return {
            favorite,
            addBoxToCart,
            addBottleToCart,
            money,
            boxCount,
            bottleCount,
            HeartIcon,
            HeartOutlineIcon,
            showTooltip: ref(false),
        };
    },
};
