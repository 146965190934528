<template>
    <div>
        <button
            v-for="tab in tabs"
            :key="tab.text"
            :class="tab.active ? 'active' : ''"
            @click="$emit('activate', tab)"
        >
            {{ tab.text }}
        </button>
    </div>
</template>

<script type="ts">

export default {
    props: {
        design: {
            default: '',
            type: String,
            required: false,
        },
        tabs: {
            type: Array,
        },
    },
    setup() {
        return {
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

// Base styles
button {
    border: 2px solid #8c8c8c;
    font-family: 'DINPro';
    font-size: 16px;
    font-weight: 500;
    font-stretch: semi-condensed;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0.75rem;
    height: 2.5rem;
    min-width: 9.25rem;
    background: $white;
    &:focus {
        outline: none;
    }

    &:nth-of-type(1) {
        border-right: none;
    }

    &:nth-of-type(2) {
        border-left: none;
    }

    &.active {
        background: $kwast-red;
        border-color: $kwast-red;
        color: $white;
    }
}

@media only screen and (max-width: 992px) {
    button {
        min-width: 8.7rem;
    }
}
</style>
