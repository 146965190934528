import { Wine } from '@/shared/models/Wine';

export class Order {
    id!: string;
    no!: string;
    order_date?: string;
    delivery_date?: string;
    amount!: number;
    status!: string;
    completely_shipped!: boolean;
    saleslines!: Array<Wine>;
}
