
import Button from './Button.vue';

export default {
    props: {
        text: {
            type: String,
        },
        buttonText: {
            type: String,
        },
    },
    components: {
        Button,
    },
    setup() {
        return {
        };
    },
};
