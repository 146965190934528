
import { plainToClass } from 'class-transformer';
import {
    onMounted, onUnmounted, reactive, ref,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import OrderItem from '@/components/OrderItem.vue';
import ButtonSwitch from '@/shared/components/ButtonSwitch.vue';
import CardButton from '@/shared/components/CardButton.vue';
import TwoColPage from '@/shared/components/TwoColPage.vue';
import { Order } from '@/shared/models/Order';
import { Reservation } from '@/shared/models/Reservation';

export default {
    components: {
        OrderItem,
        TwoColPage,
        ButtonSwitch,
        CardButton,
    },
    setup() {
        const store = useStore();
        const tabs = reactive([
            {
                text: 'Bestellingen',
                active: true,
            },
        ]);
        const orders = ref(Array<Order>());
        const groupedOrders = ref([]);
        const reservations = ref(Array<Reservation>());
        const groupedReservations = ref([]);

        const groupBy = (key: string, array: any) => array.reduce((objectsByKeyValue: any, obj: any) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {});

        function getOrders() {
            store.dispatch('GET_ORDERS').then((res) => {
                orders.value = plainToClass(Order, res.data.orders as Order[]);
                groupedOrders.value = groupBy('order_date', orders.value);
                store.dispatch('GET_RESERVATIONS').then((response) => {
                    reservations.value = plainToClass(Reservation, response.data.reservations as Reservation[]);
                    groupedReservations.value = groupBy('date', reservations.value);
                });
            });
        }
        
        onMounted(() => {
            getOrders();
        });

        function activateTab(currentTab: any) {
            tabs.forEach((tab) => {
                tab.active = tab.text === currentTab.text;
            });
        }

        function openContactUrl() {
            window.open('https://www.kwastwijnkopers.nl/contact/', '_blank');
        }

        const router = useRouter();
        function navigateTo(url: any) {
            window.scrollTo(0, 0);
            router.push(url);
        }

        function navigateToAssortiment() {
            navigateTo('assortiment');
        }

        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;
        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });
        return {
            tabs,
            activateTab,
            openContactUrl,
            groupedOrders,
            groupedReservations,
            orders,
            reservations,
            navigateToAssortiment,
            width,
        };
    },
};
