export default function () {
    function getMoneyString(value?: any) {
        if (!value) {
            return '0,00';
        }

        return String(value.toFixed(2)).replace('.', ',');
    }

    return {
        getMoneyString,
    };
}
