
import moment from 'moment';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ChevronDown from '@/assets/icons/chevron-down.svg';
import ChevronUp from '@/assets/icons/chevron-up.svg';
import money from '@/helpers/moneyString';
import BarItem from '@/shared/components/BarItem.vue';
import Button from '@/shared/components/Button.vue';
import { Order } from '@/shared/models/Order';
import { Wine } from '@/shared/models/Wine';

export default {
    props: {
        order: {
            type: Order,
            required: true,
        },
        showDate: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BarItem,
        Button,
    },
    setup(props: any) {
        const orderObject = ref(props.order);
        const show = ref(false);
        const store = useStore();
        const total = computed(() => {
            let amount = 0;
            orderObject.value.saleslines.forEach((saleLine: any) => {
                amount += saleLine.amount;
            });

            return amount;
        });

        function orderAgain() {
            orderObject.value.saleslines.forEach((saleLine: any) => {
                store.dispatch('CART_UPDATE', {
                    salesorder: orderObject.value.no, item_no: saleLine.item.id, quantity: saleLine.quantity,
                });
            });
        }

        function truncate(string?: string) {
            const max = 27;
            if (string) {
                return string.length > max ? `${string.slice(0, max - 1)}…` : string;
            }

            return 'Geen naam';
        }

        function salesLineIsNotRevenue(saleLine: Wine) {
            return saleLine.type !== 'Revenue';
        }

        return {
            truncate,
            total,
            show,
            ChevronDown,
            ChevronUp,
            orderAgain,
            moment,
            money,
            salesLineIsNotRevenue,
        };
    },
};
