import { Relation, Wine } from '@/shared/models/Wine';

export class CartItem {
    amount!: number;
    discount_amount?: number;
    discount_percentage?: number;
    discount_description?: string;
    item!: Wine;
    price!: number;
    quantity!: number;
    salesorder?: string;
    salesreservation?: string;
}
