
import moment from 'moment';
import {
    computed, onBeforeMount, onMounted, onUnmounted, ref, watch, 
} from 'vue';
import DatePicker from 'vue3-datepicker';
import { useStore } from 'vuex';
import ChevronBack from '@/assets/icons/chevron-back-outline.svg';
import moneyString from '@/helpers/moneyString';
import AddressItem from '@/shared/components/AddressItem.vue';
import BarItem from '@/shared/components/BarItem.vue';
import Button from '@/shared/components/Button.vue';
import DropdownButton from '@/shared/components/DropdownButton.vue';
import Checkbox from '@/shared/components/form/Checkbox.vue';
import RadioSelect from '@/shared/components/form/RadioSelect.vue';

export default {
    props: {
        total: {
            type: Number,
            default: 0,
        },
        formData: {
            type: Object,
        },
        accepted: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Checkbox,
        DropdownButton,
        RadioSelect,
        BarItem,
        AddressItem,
        DatePicker,
        Button,
        ChevronBack,
    },
    setup(props: any, context: any) {
        const formDataRef = ref(props.formData);
        const addresses = ref(Array<any>());
        const orderTypes = ref([
            {
                id: 1,
                checked: true,
            },
            {
                id: 2,
                checked: false,
            },
        ]);
        const orderDateTypes = ref([
            {
                id: 1,
                checked: true,
            },
            {
                id: 2,
                checked: false,
            },
        ]);
        const primaryAddress = ref({});
        const deliveryDate = ref('');
        const pickupDate = ref('');
        const note = ref('');
        const date = ref(new Date());
        const lowerLimit = computed(() => date.value);
        const upperLimit = ref(new Date());
        const holidays = ref({
            dates: Array<Date>(),
        });
        const acceptedRef = ref(props.accepted);
        const store = useStore();
        const steps = ref([
            {
                id: 1,
                active: true,
            },
            {
                id: 2,
                active: false,
            },
            {
                id: 3,
                active: false,
            },
        ]);

        function isActiveStep(id: number) {
            return steps.value.find((tab) => tab.id === id)?.active;
        }

        function setActiveStep(id: number) {
            steps.value.map((tab: any) => {
                if (tab.id === id) {
                    tab.active = true;
                    return tab;
                }

                tab.active = false;
                return tab;
            });
        }

        function getActiveStep() {
            return steps.value.find((tab) => tab.active);
        }

        function goBack() {
            const activeStep = getActiveStep();
            if (activeStep) {
                if (activeStep.id === 1) {
                    window.scrollTo(0, 0);
                    context.emit('back');
                } else {
                    setActiveStep(activeStep.id - 1);
                    window.scrollTo(0, 0);
                }
            }
        }

        const selectedOrderType = computed(() => orderTypes.value.find((item) => item.checked));
        const selectedAddress = computed(() => addresses.value.find((item) => item.checked));
        const selectedOrderDateType = computed(() => orderDateTypes.value.find((item) => item.checked));

        function getDate() {
            if (selectedOrderDateType.value && selectedOrderDateType.value.id === 1) {
                if (selectedOrderType.value && selectedOrderType.value.id === 1) {
                    return moment(deliveryDate.value, 'DD-MM-yy').format('yy-MM-DD');
                }

                return moment(pickupDate.value, 'DD-MM-yy').format('yy-MM-DD');
            }

            return moment(date.value).format('yy-MM-DD');
        }

        function getAddressCode() {
            if (selectedOrderType.value && selectedOrderType.value.id === 1) {
                if (selectedAddress.value) {
                    return selectedAddress.value.code;
                }
            }

            return null;
        }

        function selectAddress() {
            formDataRef.value.address_code = getAddressCode();
        }

        watch(date, () => {
            formDataRef.value.requested_delivery_date = getDate();
        });

        function selectOrderDateType(index: number) {
            orderDateTypes.value.map((item: any) => item.checked = false);
            orderDateTypes.value[index].checked = true;
            formDataRef.value.requested_delivery_date = getDate();
        }

        function selectOrdertype(index: number) {
            orderTypes.value.map((item: any) => item.checked = false);
            orderTypes.value[index].checked = true;
            formDataRef.value.shipping_code = getAddressCode() ? 'ZV' : 'AFHAAL';
            formDataRef.value.address_code = getAddressCode();
            formDataRef.value.requested_delivery_date = getDate();
        }

        function getWeekendDates(startDate: Date, endDate: Date) {
            const dates = [];
            let currentDate = startDate;
            const addDays = (days: any) => {
                const theDate = new Date(currentDate);
                theDate.setDate(theDate.getDate() + days);
                return theDate;
            };
            while (currentDate <= endDate) {
                if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
                    dates.push(currentDate);
                }
                currentDate = addDays.call(currentDate, 1);
            }
            return dates;
        }

        function getAddresses() {
            store.dispatch('AUTH_INFO').then((res) => {
                const addressesArray = [];
                primaryAddress.value = res.data.data.address_default;
                addressesArray.push(res.data.data.address_default);
                res.data.data.addresses.forEach((address: any) => {
                    addressesArray.push(address);
                });

                addressesArray.map((address: any, index: number) => {
                    if (address) {
                        address.name = `${address.address} | ${address.zipcode} ${address.city}`;

                        // Primary address should be selected by default
                        if (index === 0) {
                            address.checked = true;
                        } else {
                            address.checked = false;
                        }
                    }

                    return address;
                });

                if (addressesArray && addressesArray.length > 0) {
                    addresses.value = addressesArray;
                }

                selectOrdertype(0);
            });

            store.dispatch('GET_TIMES').then((res: any) => {
                deliveryDate.value = res.data.delivery;
                pickupDate.value = res.data.pickup;
                date.value = moment(res.data.delivery, 'DD-MM-YYYY').toDate();
                res.data.holidays.forEach((holiday: any) => {
                    holidays.value.dates.push(moment(holiday, 'DD-MM-YYYY').toDate());
                });

                const now = new Date();
                const year = now.getFullYear();
                const month = now.getMonth();
                const day = now.getDate();
                const nextYear = new Date(year + 1, month, day);
                upperLimit.value = nextYear;

                const dates = getWeekendDates(new Date(), nextYear);
                dates.forEach((dateItem: any) => {
                    holidays.value.dates.push(dateItem);
                });

                selectOrderDateType(0);
            });
        }

        function nextStep() {
            const ActiveStep = getActiveStep();
            if (ActiveStep) {
                if (ActiveStep.id === 3) {
                    context.emit('next');
                } else {
                    setActiveStep(ActiveStep.id + 1);
                    const element = document.getElementById('back');
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }
        }

        onBeforeMount(() => {
            getAddresses();
        });

        const width = ref(window.innerWidth);
        const onWidthChange = () => width.value = window.innerWidth;

        onMounted(() => {
            window.addEventListener('resize', onWidthChange);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onWidthChange);
        });

        return {
            selectedOrderType,
            selectedAddress,
            selectedOrderDateType,
            lowerLimit,
            upperLimit,
            addresses,
            orderTypes,
            orderDateTypes,
            selectAddress,
            selectOrdertype,
            selectOrderDateType,
            moment,
            date,
            primaryAddress,
            deliveryDate,
            pickupDate,
            holidays,
            acceptedRef,
            note,
            width,
            isActiveStep,
            nextStep,
            goBack,
            moneyString,
            formDataRef,
        };
    },
};
