import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-start date"
}
const _hoisted_2 = { class: "d-flex flex-row justify-content-between align-items-start w-100" }
const _hoisted_3 = { class: "order-item-col status-item d-flex flex-column align-items-start" }
const _hoisted_4 = { class: "d-flex flex-row align-items-start justify-content-center" }
const _hoisted_5 = { class: "order-item-col d-flex flex-column align-items-start" }
const _hoisted_6 = { class: "d-flex flex-row align-items-center justify-content-center" }
const _hoisted_7 = { class: "order-item-col d-flex flex-column align-items-start" }
const _hoisted_8 = { class: "d-flex flex-row align-items-center justify-content-center" }
const _hoisted_9 = { class: "order-item-col d-flex flex-column align-items-start" }
const _hoisted_10 = { class: "d-flex flex-row align-items-center justify-content-center" }
const _hoisted_11 = { class: "position-relative" }
const _hoisted_12 = { class: "order-item-col d-flex" }
const _hoisted_13 = { class: "order-item-col d-flex flex-column align-items-start" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "order-item-col d-flex flex-column align-items-start" }
const _hoisted_16 = { class: "fw-bold text-start" }
const _hoisted_17 = { class: "order-item-col d-flex flex-column align-items-end" }
const _hoisted_18 = { class: "order-item-col d-flex flex-column align-items-end" }
const _hoisted_19 = { class: "order-item-col d-flex flex-column align-items-end" }
const _hoisted_20 = { class: "order-item-col d-flex flex-column align-items-end fw-bold" }
const _hoisted_21 = { class: "order-item-col d-flex flex-column order-item position-relative" }
const _hoisted_22 = { class: "order-item-col status-item d-flex" }
const _hoisted_23 = { class: "title w-50" }
const _hoisted_24 = { class: "d-flex flex-row align-items-start justify-content-start w-50" }
const _hoisted_25 = { class: "order-item-col d-flex" }
const _hoisted_26 = { class: "order-item-col d-flex" }
const _hoisted_27 = { class: "d-flex flex-row align-items-center justify-content-center" }
const _hoisted_28 = { class: "position-relative" }
const _hoisted_29 = {
  key: 0,
  class: "sale-item"
}
const _hoisted_30 = { class: "item d-flex flex-row justify-content-between align-items-center" }
const _hoisted_31 = { class: "image-box w-25" }
const _hoisted_32 = ["src"]
const _hoisted_33 = { class: "info-box w-75 d-flex flex-column" }
const _hoisted_34 = { class: "d-flex" }
const _hoisted_35 = { class: "fw-bold text-start" }
const _hoisted_36 = { class: "text-start mt-1" }
const _hoisted_37 = { class: "d-flex flex-row justify-content-between mt-3" }
const _hoisted_38 = { class: "fw-bold" }
const _hoisted_39 = { class: "position-relative price" }
const _hoisted_40 = {
  key: 0,
  class: "py-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_BarItem = _resolveComponent("BarItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.showDate)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($props.order.order_date ? $setup.moment($props.order.order_date).locale('nl').format('dddd LL') : 'Overige'), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_BarItem, {
      design: "high-bar",
      show: $setup.show,
      class: "d-none d-lg-block"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "title" }, " Status: ", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["status", $props.order.status.toLowerCase().replace(' ', '-')])
              }, null, 2),
              _createElementVNode("div", null, _toDisplayString($props.order.status), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "title" }, " Bestelnummer: ", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, _toDisplayString($props.order.no), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "title" }, " Leverdatum: ", -1)),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, _toDisplayString($props.order.delivery_date), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "title" }, " Totaal: ", -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createTextVNode(" €" + _toDisplayString($setup.money().getMoneyString($setup.total)) + " ", 1),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Button, {
              text: "plaats in winkelmand",
              design: "primary",
              onClick: $setup.orderAgain
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", {
            class: "order-item-col d-flex flex-column align-items-center justify-content-center hoverable",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.show = !$setup.show))
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.show ? $setup.ChevronUp : $setup.ChevronDown), { class: "chevron-icon" }))
          ])
        ])
      ]),
      items: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.order.saleslines, (saleLine) => {
          return (_openBlock(), _createElementBlock("div", { key: saleLine }, [
            ($setup.salesLineIsNotRevenue(saleLine))
              ? (_openBlock(), _createBlock(_component_BarItem, {
                  key: 0,
                  class: "order-item",
                  divider: "top",
                  design: "high-bar no-margin"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("img", {
                        style: {"max-height":"80px","margin":"0 10px"},
                        src: saleLine.item?.image_small,
                        alt: "wine"
                      }, null, 8, _hoisted_14)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString($setup.truncate(saleLine.item?.name)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(saleLine.item?.year_of_harvest !== '0' ?  saleLine.item?.year_of_harvest : 'N.M.'), 1),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(saleLine.item?.volume) + " L ", 1),
                    _createElementVNode("div", _hoisted_19, _toDisplayString(saleLine.item?.units_per_parcel) + " Fl. ", 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(saleLine.quantity) + "x ", 1),
                    _createElementVNode("div", _hoisted_21, [
                      _createTextVNode(" €" + _toDisplayString($setup.money().getMoneyString(saleLine.item?.price)) + " ", 1),
                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "netto" }, " bruto ", -1))
                    ])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_BarItem, {
      show: $setup.show,
      class: "d-block d-lg-none order-item"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("p", _hoisted_23, _toDisplayString($props.order.no), 1),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", {
              class: _normalizeClass(["status", $props.order.status.toLowerCase().replace(' ', '-')])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass($props.order.status.toLowerCase().replace(' ', '-'))
            }, _toDisplayString($props.order.status), 3)
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "title" }, " Leverdatum ", -1)),
          _createElementVNode("div", null, _toDisplayString($props.order.delivery_date), 1)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "title" }, " Totaal ", -1)),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, " €" + _toDisplayString($setup.money().getMoneyString($setup.total)), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "order-item-col d-flex flex-row justify-content-center hoverable mt-3 align-items-center",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.show = !$setup.show))
        }, [
          _createTextVNode(_toDisplayString(!$setup.show ? 'Meer bekijken' : 'Sluiten') + " ", 1),
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.show ? $setup.ChevronUp : $setup.ChevronDown), { class: "chevron-icon" }))
        ])
      ]),
      items: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.order.saleslines, (saleLine, index) => {
          return (_openBlock(), _createElementBlock("div", { key: saleLine }, [
            ($setup.salesLineIsNotRevenue(saleLine))
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "divider" }, null, -1)),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("img", {
                        src: saleLine.item?.image_small,
                        alt: "wine"
                      }, null, 8, _hoisted_32)
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("p", _hoisted_35, _toDisplayString($setup.truncate(saleLine.item?.name)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_36, _toDisplayString(saleLine.item?.year_of_harvest) + " | " + _toDisplayString(saleLine.item?.volume) + " L | " + _toDisplayString(saleLine.item?.units_per_parcel) + " Fl. ", 1),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, _toDisplayString(saleLine.quantity) + " Flessen ", 1),
                        _createElementVNode("div", _hoisted_39, [
                          _createTextVNode(" €" + _toDisplayString($setup.money().getMoneyString(saleLine.item?.price)) + " ", 1),
                          _cache[10] || (_cache[10] = _createElementVNode("span", { class: "netto" }, " bruto ", -1))
                        ])
                      ])
                    ])
                  ]),
                  (index === $props.order.saleslines.length - 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                        _createVNode(_component_Button, {
                          text: "plaats in winkelmand",
                          design: "primary",
                          onClick: $setup.orderAgain
                        }, null, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}