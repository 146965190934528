import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex flex-row w-50" }
const _hoisted_2 = { class: "img-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info-box d-flex flex-column justify-content-between align-items-md-start flex-grow-1 position-relative" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "d-md-none" }
const _hoisted_7 = { class: "text-start" }
const _hoisted_8 = { class: "red" }
const _hoisted_9 = { class: "d-none d-md-block text-start" }
const _hoisted_10 = { class: "d-flex d-md-none flex-row justify-content-between" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = { class: "small" }
const _hoisted_13 = { class: "position-relative text-end light medium" }
const _hoisted_14 = { class: "d-flex flex-column" }
const _hoisted_15 = { class: "position-relative text-end fw-bold" }
const _hoisted_16 = {
  class: "medium text-start",
  style: {"margin-left":"10px"}
}
const _hoisted_17 = { class: "d-none d-md-flex flex-column w-50" }
const _hoisted_18 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_19 = { class: "d-flex flex-column align-items-start" }
const _hoisted_20 = { class: "fst-italic light" }
const _hoisted_21 = { class: "d-flex flex-column align-items-start" }
const _hoisted_22 = {
  key: 0,
  class: "fst-italic light"
}
const _hoisted_23 = { class: "d-flex flex-row justify-content-end mt-2" }
const _hoisted_24 = { class: "d-flex flex-column mx-3" }
const _hoisted_25 = { class: "small" }
const _hoisted_26 = { class: "position-relative text-end light medium" }
const _hoisted_27 = { class: "d-flex flex-column" }
const _hoisted_28 = { class: "position-relative text-end fw-bold" }
const _hoisted_29 = { class: "d-flex d-md-none flex-row justify-content-between mt-3" }
const _hoisted_30 = { class: "d-flex flex-column align-items-start" }
const _hoisted_31 = { class: "fst-italic light" }
const _hoisted_32 = { class: "d-flex flex-column align-items-start" }
const _hoisted_33 = { style: {"font-size":"12px"} }
const _hoisted_34 = {
  key: 0,
  class: "fst-italic light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_CounterButton = _resolveComponent("CounterButton")!
  const _component_BarItem = _resolveComponent("BarItem")!

  return (_openBlock(), _createBlock(_component_BarItem, { design: "no-margin" }, {
    content: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["wine-item position-relative d-flex flex-column flex-md-row justify-content-between w-100", {'border-bottom': $props.divider}])
      }, [
        _createVNode(_component_CloseIcon, {
          class: "position-absolute close-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeItem')))
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: $props.cartItem.item.image_small,
              alt: "Wine"
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, [
              ($props.cartItem.item.type === 'wine')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.cartItem.item.year_of_harvest !== '0' ? $props.cartItem.item.year_of_harvest : 'N.M.'), 1))
                : _createCommentVNode("", true),
              ($props.cartItem.item.type === 'wine')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["circle", $props.cartItem.item.inventory_color]),
                    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => ($setup.showTooltip = true)),
                    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => ($setup.showTooltip = false))
                  }, null, 34))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_6, " €" + _toDisplayString($setup.money.getMoneyString($props.cartItem.item.price)), 1),
              _createVNode(_component_Tooltip, {
                showTooltip: $setup.showTooltip,
                "arrow-style": "left: calc(50% - 8px);"
              }, null, 8, ["showTooltip"])
            ]),
            _createElementVNode("h4", _hoisted_7, _toDisplayString($props.cartItem.item.name), 1),
            _createElementVNode("h5", _hoisted_8, _toDisplayString($props.cartItem.discount_description), 1),
            _createElementVNode("p", _hoisted_9, [
              _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString($props.cartItem.item.price)) + " ", 1),
              ($props.cartItem.item.type !== 'wine')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["circle", $props.cartItem.item.inventory_color])
                  }, null, 2))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, " Per " + _toDisplayString($props.cartItem.item.type === 'wine' ? 'fles' : $props.cartItem.item.type === 'zalto' ? 'glas' : 'stopper'), 1),
                _createElementVNode("p", _hoisted_13, [
                  _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString($props.cartItem.price)) + " ", 1),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[18] || (_cache[18] = _createElementVNode("p", { class: "small" }, " Subtotaal ", -1)),
                _createElementVNode("p", _hoisted_15, [
                  _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString($props.cartItem.amount)) + " ", 1),
                  _cache[17] || (_cache[17] = _createElementVNode("span", { class: "netto fw-lighter" }, " netto ", -1))
                ])
              ])
            ]),
            ($props.cartItem.item.type === 'wine')
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "d-none d-md-block text-start d-flex flex-row justify-content-start align-items-center clickable",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.favorite && $setup.favorite(...args)))
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent($props.cartItem.item.is_favorited ? $setup.HeartIcon : $setup.HeartOutlineIcon), { class: "heart-icon" })),
                  _createElementVNode("span", _hoisted_16, _toDisplayString($props.cartItem.item.is_favorited ? 'Van' : 'Aan') + " favorieten " + _toDisplayString($props.cartItem.item.is_favorited ? 'verwijderen' : 'toevoegen'), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[19] || (_cache[19] = _createElementVNode("p", null, "Dozen", -1)),
              _createVNode(_component_CounterButton, {
                modelValue: $setup.boxCount,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.boxCount) = $event)),
                onMinus: _cache[5] || (_cache[5] = ($event: any) => ($setup.addBoxToCart($event, $props.cartItem))),
                onPlus: _cache[6] || (_cache[6] = ($event: any) => ($setup.addBoxToCart($event, $props.cartItem)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("span", _hoisted_20, _toDisplayString($props.cartItem.item.units_per_parcel) + " " + _toDisplayString($props.cartItem.item.type === 'wine' ? `x ${$props.cartItem.item.volume}L per doos` : 'x per doos'), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("p", null, _toDisplayString($props.cartItem.item.type === 'wine' ? 'Flessen' : $props.cartItem.item.type === 'zalto' ? 'Glazen' : 'Stoppers'), 1),
              _createVNode(_component_CounterButton, {
                modelValue: $setup.bottleCount,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.bottleCount) = $event)),
                onMinus: _cache[8] || (_cache[8] = ($event: any) => ($setup.addBottleToCart($event, $props.cartItem))),
                onPlus: _cache[9] || (_cache[9] = ($event: any) => ($setup.addBottleToCart($event, $props.cartItem)))
              }, null, 8, ["modelValue"]),
              ($props.cartItem.item.type === 'wine')
                ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString($props.cartItem.item.volume) + "L per fles ", 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("p", _hoisted_25, " Per " + _toDisplayString($props.cartItem.item.type === 'wine' ? 'fles' : $props.cartItem.item.type === 'zalto' ? 'glas' : 'stopper'), 1),
              _createElementVNode("p", _hoisted_26, [
                _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString($props.cartItem.price)) + " ", 1),
                _cache[20] || (_cache[20] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _cache[22] || (_cache[22] = _createElementVNode("p", { class: "small" }, " Subtotaal ", -1)),
              _createElementVNode("p", _hoisted_28, [
                _createTextVNode(" €" + _toDisplayString($setup.money.getMoneyString($props.cartItem.amount)) + " ", 1),
                _cache[21] || (_cache[21] = _createElementVNode("span", { class: "netto fw-lighter" }, " netto ", -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _cache[23] || (_cache[23] = _createElementVNode("p", { style: {"font-size":"12px"} }, " Dozen ", -1)),
            _createVNode(_component_CounterButton, {
              modelValue: $setup.boxCount,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.boxCount) = $event)),
              onMinus: _cache[11] || (_cache[11] = ($event: any) => ($setup.addBoxToCart($event, $props.cartItem))),
              onPlus: _cache[12] || (_cache[12] = ($event: any) => ($setup.addBoxToCart($event, $props.cartItem)))
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_31, _toDisplayString($props.cartItem.item.units_per_parcel) + " " + _toDisplayString($props.cartItem.item.type === 'wine' ? `x ${$props.cartItem.item.volume}L per doos` : 'x per doos'), 1)
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("p", _hoisted_33, _toDisplayString($props.cartItem.item.type === 'wine' ? 'Flessen' : $props.cartItem.item.type === 'zalto' ? 'Glazen' : 'Stoppers'), 1),
            _createVNode(_component_CounterButton, {
              modelValue: $setup.bottleCount,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.bottleCount) = $event)),
              onMinus: _cache[14] || (_cache[14] = ($event: any) => ($setup.addBottleToCart($event, $props.cartItem))),
              onPlus: _cache[15] || (_cache[15] = ($event: any) => ($setup.addBottleToCart($event, $props.cartItem)))
            }, null, 8, ["modelValue"]),
            ($props.cartItem.item.type === 'wine')
              ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString($props.cartItem.item.volume) + "L per fles ", 1))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2)
    ]),
    _: 1
  }))
}