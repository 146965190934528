
import { ref } from 'vue';

export default {
    props: {
        hasErrors: {
            default: false,
        },
        label: {
            type: String,
        },
        modelValue: Boolean,
    },
    setup(props: any, context: any) {
        const on = ref(props.modelValue);

        function emitValue() {
            on.value = !on.value;
            context.emit('update:modelValue', on.value);
        }

        return {
            emitValue,
            on,
        };
    },
};
