
import { useRouter } from 'vue-router';
import moneyString from '@/helpers/moneyString';
import BarItem from '@/shared/components/BarItem.vue';
import Button from '@/shared/components/Button.vue';
import LogoDivider from '@/shared/components/LogoDivider.vue';
import PageFooter from '@/shared/components/PageFooter.vue';
import { Order } from '@/shared/models/Order';

export default {
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
    components: {
        PageFooter,
        BarItem,
        Button,
        LogoDivider,
    },
    setup() {
        const money = moneyString();
        const router = useRouter();
        
        function navigateTo(url: any) {
            window.scrollTo(0, 0);
            router.push(url);
        }

        function navigateToAssortiment() {
            navigateTo({ name: 'Assortiment' });
        }

        function openContact() {
            window.open('https://www.kwastwijnkopers.nl/contact/', '_blank');
        }

        return {
            money,
            navigateToAssortiment,
            openContact,
        };
    },
};
