

export default {
    props: {
        tabs: {
            type: Array,
        },
    },
    setup(props: any) {
        function activateTab(tab: any) {
            if (!tab.disabled) {
                props.tabs.map((item: any) => item.active = false);
                tab.active = true;
            }
        }

        return {
            activateTab,
        };
    },
};
