import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "cart" }
const _hoisted_2 = {
  key: 0,
  id: "info-text",
  class: "text-start red fw-bold"
}
const _hoisted_3 = { class: "order" }
const _hoisted_4 = {
  key: 0,
  class: "complete w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabSwitcher = _resolveComponent("TabSwitcher")!
  const _component_CartItem = _resolveComponent("CartItem")!
  const _component_OrderSteps = _resolveComponent("OrderSteps")!
  const _component_OrderComplete = _resolveComponent("OrderComplete")!
  const _component_CartInfo = _resolveComponent("CartInfo")!
  const _component_TwoColPage = _resolveComponent("TwoColPage")!

  return (_openBlock(), _createBlock(_component_TwoColPage, {
    title: $setup.getTitle()
  }, _createSlots({
    tabs: _withCtx(() => [
      _createVNode(_component_TabSwitcher, {
        class: "d-none d-md-flex",
        tabs: $setup.tabs
      }, null, 8, ["tabs"])
    ]),
    main: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cart, (cartItem, index) => {
          return (_openBlock(), _createBlock(_component_CartItem, {
            key: cartItem.item.id,
            class: "cart-item",
            cartItem: cartItem,
            divider: $setup.cart.length - 1 !== index,
            onRemoveItem: ($event: any) => ($setup.removeItem(cartItem)),
            onUpdateCart: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateCart($event)))
          }, null, 8, ["cartItem", "divider", "onRemoveItem"]))
        }), 128)),
        ($setup.cart && $setup.cart.length > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Franco aan huis geleverd bij een minimale waarde van " + _toDisplayString($setup.minPrice) + " euro. Alle bedragen zijn excl. BTW. ", 1))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, $setup.isActiveTab('Winkelmand')]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_OrderSteps, {
          formData: $setup.formData,
          total: $setup.total,
          accepted: $setup.accepted,
          onAccept: _cache[1] || (_cache[1] = ($event: any) => ($setup.accepted = $event)),
          onBack: _cache[2] || (_cache[2] = ($event: any) => ($setup.setActiveTab('Winkelmand'))),
          onNext: _cache[3] || (_cache[3] = ($event: any) => ($setup.order($event)))
        }, null, 8, ["formData", "total", "accepted"])
      ], 512), [
        [_vShow, $setup.isActiveTab('Bestellen')]
      ]),
      ($setup.orderRef)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_OrderComplete, { order: $setup.orderRef }, null, 8, ["order"])
          ], 512)), [
            [_vShow, $setup.isActiveTab('Bevestiging')]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    ($setup.isActiveTab('Winkelmand') || ($setup.isActiveTab('Bestellen') && $setup.width >= 768))
      ? {
          name: "info",
          fn: _withCtx(() => [
            _createVNode(_component_CartInfo, {
              total_quantity: $setup.total_quantity,
              shipping_costs: $setup.shipping_costs,
              cart: $setup.cart,
              total: $setup.total,
              activeTab: $setup.currentTab,
              accepted: $setup.accepted,
              error: $setup.serverValidation,
              onOrder: $setup.order,
              onActivate: _cache[4] || (_cache[4] = ($event: any) => ($setup.setActiveTab($event)))
            }, null, 8, ["total_quantity", "shipping_costs", "cart", "total", "activeTab", "accepted", "error", "onOrder"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["title"]))
}