import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "steps"
}
const _hoisted_2 = {
  key: 0,
  id: "back"
}
const _hoisted_3 = { class: "dividers d-flex flex-row justify-content-between align-items-center" }
const _hoisted_4 = {
  key: 1,
  id: "overview",
  class: "overview"
}
const _hoisted_5 = { class: "d-flex flex-row justify-content-between ghost" }
const _hoisted_6 = { class: "fw-normal" }
const _hoisted_7 = { class: "d-flex flex-row justify-content-between ghost" }
const _hoisted_8 = { class: "d-flex flex-column fw-bold" }
const _hoisted_9 = { class: "fw-normal" }
const _hoisted_10 = { class: "d-flex flex-row justify-content-between ghost" }
const _hoisted_11 = { class: "fw-bold" }
const _hoisted_12 = { class: "position-relative fw-normal" }
const _hoisted_13 = {
  id: "step-1",
  class: "step-1 mb-3"
}
const _hoisted_14 = { class: "d-flex flex-column align-items-start" }
const _hoisted_15 = { class: "d-flex flex-column align-items-start" }
const _hoisted_16 = { class: "d-flex flex-column align-items-start" }
const _hoisted_17 = {
  id: "step-2",
  class: "step-2 mb-3"
}
const _hoisted_18 = { class: "d-flex flex-column align-items-start flex-grow-1" }
const _hoisted_19 = { class: "d-flex flex-column align-items-start w-100" }
const _hoisted_20 = { class: "clickable" }
const _hoisted_21 = { class: "d-flex flex-column align-items-start mt-3 w-100" }
const _hoisted_22 = {
  id: "step-3",
  class: "step-3 mb-3"
}
const _hoisted_23 = { class: "d-flex flex-column align-items-start pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronBack = _resolveComponent("ChevronBack")!
  const _component_BarItem = _resolveComponent("BarItem")!
  const _component_DropdownButton = _resolveComponent("DropdownButton")!
  const _component_RadioSelect = _resolveComponent("RadioSelect")!
  const _component_AddressItem = _resolveComponent("AddressItem")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return ($setup.addresses && $setup.addresses.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.width <= 767)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "back-button d-flex align-items-center clickable",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.goBack && $setup.goBack(...args)))
              }, [
                _createVNode(_component_ChevronBack, { class: "chevron-back" }),
                _cache[9] || (_cache[9] = _createTextVNode(" Terug "))
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(["divider w-100", $setup.isActiveStep(1) ? 'red' : ''])
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["divider w-100", $setup.isActiveStep(2) ? 'red' : ''])
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["divider w-100", $setup.isActiveStep(3) ? 'red' : ''])
                }, null, 2)
              ])
            ]))
          : _createCommentVNode("", true),
        ($setup.width <= 767)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              ($setup.isActiveStep(1) || $setup.isActiveStep(2) || $setup.isActiveStep(3))
                ? (_openBlock(), _createBlock(_component_BarItem, {
                    key: 0,
                    design: "small"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "d-flex flex-column fw-bold" }, [
                          _createTextVNode(" Totaal "),
                          _createElementVNode("span", { class: "fw-light" }, "Inclusief 21% BTW")
                        ], -1)),
                        _createElementVNode("h3", _hoisted_6, " €" + _toDisplayString($setup.moneyString().getMoneyString($props.total)), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($setup.isActiveStep(2) || $setup.isActiveStep(3))
                ? (_openBlock(), _createBlock(_component_BarItem, {
                    key: 1,
                    design: "small"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h4", _hoisted_8, _toDisplayString($setup.selectedOrderType.id === 1 ? 'Bezorgadres' : 'Afhaaladres'), 1),
                        _createElementVNode("h4", _hoisted_9, _toDisplayString($setup.selectedOrderType.id === 1 ? $setup.selectedAddress.address : _ctx.pickupAddress.address), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($setup.isActiveStep(3))
                ? (_openBlock(), _createBlock(_component_BarItem, {
                    key: 2,
                    design: "small"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h4", _hoisted_11, _toDisplayString($setup.selectedOrderType.id === 1 ? 'Leverdatum' : 'Afhaaldatum'), 1),
                        _createElementVNode("h4", _hoisted_12, _toDisplayString($setup.selectedOrderDateType.id === 1 ? $setup.selectedOrderType.id === 1 ? $setup.deliveryDate : $setup.pickupDate : $setup.moment($setup.date).format('DD-MM-yy')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_13, [
          _createVNode(_component_BarItem, { design: "no-margin" }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "mb-3" }, " BEZORGADRES ", -1)),
                  _createVNode(_component_RadioSelect, {
                    design: "no-border no-max-width",
                    label: $setup.orderTypes[0],
                    onSelect: _cache[1] || (_cache[1] = ($event: any) => ($setup.selectOrdertype(0)))
                  }, {
                    item: _withCtx(() => [
                      _createVNode(_component_DropdownButton, {
                        design: "gray",
                        text: $setup.addresses.find((item) => item.checked).name,
                        items: $setup.addresses,
                        onSelect: $setup.selectAddress
                      }, null, 8, ["text", "items", "onSelect"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "d-none d-md-block my-2" }, " Of ", -1)),
                  _createVNode(_component_RadioSelect, {
                    label: $setup.orderTypes[1],
                    design: "no-border start no-max-width",
                    onSelect: _cache[2] || (_cache[2] = ($event: any) => ($setup.selectOrdertype(1)))
                  }, {
                    item: _withCtx(() => [
                      _createVNode(_component_AddressItem, {
                        class: "address-item",
                        title: "Zelf ophalen",
                        street: "Roggestraat 30",
                        postal: "2153 GC Nieuw Vennep"
                      })
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "mb-3" }, " FACTUURADRES ", -1)),
                  _createVNode(_component_AddressItem, {
                    title: $setup.primaryAddress.city,
                    street: $setup.primaryAddress.address,
                    postal: $setup.primaryAddress.zipcode + ' ' + $setup.primaryAddress.city
                  }, null, 8, ["title", "street", "postal"])
                ])
              ])
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, $setup.isActiveStep(1) || $setup.width > 767]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_17, [
          _createVNode(_component_BarItem, { design: "no-margin" }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "mb-3" }, " LEVERDATUM ", -1)),
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "light" }, " Voor 12:00 uur besteld morgen geleverd, met uitzondering van de eilanden en Zeeland. ", -1)),
                  _createVNode(_component_RadioSelect, {
                    design: "no-border no-max-width",
                    label: $setup.orderDateTypes[0],
                    onSelect: _cache[3] || (_cache[3] = ($event: any) => ($setup.selectOrderDateType(0)))
                  }, {
                    item: _withCtx(() => [
                      _createElementVNode("h5", _hoisted_20, [
                        _cache[14] || (_cache[14] = _createTextVNode(" Zo snel mogelijk ")),
                        _createElementVNode("span", null, " (" + _toDisplayString($setup.orderTypes.find((type) => type.checked).id === 1 ? $setup.deliveryDate : $setup.pickupDate) + ") ", 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_RadioSelect, {
                    id: "datepicker",
                    label: $setup.orderDateTypes[1],
                    design: "no-border center no-max-width",
                    onSelect: _cache[5] || (_cache[5] = ($event: any) => ($setup.selectOrderDateType(1)))
                  }, {
                    item: _withCtx(() => [
                      _createVNode(_component_DatePicker, {
                        modelValue: $setup.date,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.date) = $event)),
                        inputFormat: "dd-MM-yyyy",
                        lowerLimit: $setup.lowerLimit,
                        upperLimit: $setup.upperLimit,
                        disabledDates: $setup.holidays
                      }, null, 8, ["modelValue", "lowerLimit", "upperLimit", "disabledDates"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", { class: "text-start" }, " Opmerking ", -1)),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.formDataRef.remarks) = $event)),
                    maxlength: "500"
                  }, null, 512), [
                    [_vModelText, $setup.formDataRef.remarks]
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, $setup.isActiveStep(2) || $setup.width > 767]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_22, [
          _createVNode(_component_BarItem, { design: "no-margin" }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_23, [
                _cache[18] || (_cache[18] = _createElementVNode("h4", null, "Betaling", -1)),
                _cache[19] || (_cache[19] = _createElementVNode("p", { class: "text-start" }, [
                  _createTextVNode(" De betaling geschiedt op rekening. Wilt u gebruik maken van automatische incasso? Download dan het "),
                  _createElementVNode("a", {
                    class: "red text-decoration-none fw-bold clickable",
                    href: "files/incassoformulier.pdf",
                    target: "_blank"
                  }, " automatisch incasso formulier "),
                  _createTextVNode(" en stuur deze ingevuld naar het op het formulier vermelde adres. ")
                ], -1)),
                _createVNode(_component_Checkbox, {
                  modelValue: $setup.acceptedRef,
                  "onUpdate:modelValue": [
                    _cache[7] || (_cache[7] = ($event: any) => (($setup.acceptedRef) = $event)),
                    _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('accept', $setup.acceptedRef)))
                  ],
                  label: "Ik ga akkoord met de <a class=\"red text-decoration-none fw-bold\" href=\"https://www.kwastwijnkopers.nl/contact/algemene-voorwaarden/\" target=\"_blank\">\n                                        algemene voorwaarden\n                                    </a>",
                  "has-errors": !$setup.acceptedRef
                }, null, 8, ["modelValue", "has-errors"])
              ])
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, $setup.isActiveStep(3) || $setup.width > 767]
        ]),
        ($setup.width <= 767)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              style: {"float":"right"},
              text: $setup.isActiveStep(3) ? 'Bestelling plaatsen' : 'volgende stap',
              design: $setup.isActiveStep(3) ? 'red' : 'primary',
              onButtonClick: $setup.nextStep
            }, null, 8, ["text", "design", "onButtonClick"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}