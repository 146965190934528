import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-button" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "text",
      innerHTML: $props.text
    }, null, 8, _hoisted_2),
    _createVNode(_component_Button, {
      text: $props.buttonText,
      design: "primary",
      onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('buttonClick')))
    }, null, 8, ["text"])
  ]))
}