<template>
    <ul class="list-unstyled">
        <li class="list-title">
            {{title}}
        </li>
        <li v-if="name">
            {{name}}
        </li>
        <li v-if="street">
            {{street}}
        </li>
        <li v-if="postal">
            {{postal}}
        </li>
    </ul>
</template>

<script type="ts">

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: false,
        },
        street: {
            type: String,
            required: false,
        },
        postal: {
            type: String,
            required: false,
        },
    },
    setup() {
        return {
            //
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

ul {
    list-style: none;
    text-align: left;
    padding: 0;
    font-family: "DINPro", serif !important;
    font-weight: 300;
    line-height: 26px;
    margin: 0 0 40px;

    .list-title {
        line-height: 1.63;
        font-weight: 600;
    }

    li {
        margin: 1.5px 0;
    }
}

@media only screen and(max-width: 992px) {
    ul {
        margin: 0 0 20px;
    }
}
</style>
