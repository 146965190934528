import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch-wrapper d-flex flex-row justify-content-start align-items-start" }
const _hoisted_2 = ["checked"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      checked: $setup.on,
      class: _normalizeClass({
                error: $props.hasErrors,
            }),
      type: "checkbox",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.emitValue && $setup.emitValue(...args)))
    }, null, 10, _hoisted_2),
    _createElementVNode("label", {
      class: "text-start",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.emitValue && $setup.emitValue(...args))),
      innerHTML: $props.label
    }, null, 8, _hoisted_3)
  ]))
}