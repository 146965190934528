
import money from '@/helpers/moneyString';
import Accordion from '@/shared/components/Accordion.vue';
import Button from '@/shared/components/Button.vue';

export default {
    props: {
        total_quantity: {
            type: Number,
            default: 0,
        },
        shipping_costs: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
        },
        accepted: {
            type: Boolean,
            default: false,
        },
        cart: {
            type: Array,
            required: true,
        },
        activeTab: {
            type: Object,
            required: true,
        },
        error: {
            type: String,
            default: '',
        },
    },
    components: {
        Button,
        Accordion,
    },
    setup() {
        return {
            money,
        };
    },
};
