import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "cart-info" }
const _hoisted_2 = { class: "cart-info" }
const _hoisted_3 = { class: "d-flex w-25 fw-bold" }
const _hoisted_4 = { class: "d-flex flex-column w-75" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "d-flex flex-row justify-content-between pt-3"
}
const _hoisted_7 = { class: "position-relative" }
const _hoisted_8 = { class: "position-relative" }
const _hoisted_9 = {
  key: 1,
  class: "d-flex flex-row justify-content-start"
}
const _hoisted_10 = { class: "text-start red fw-bold small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Overzicht", -1)),
      _createVNode(_component_Accordion, {
        class: "text-start",
        label: $props.total_quantity === 1 ? $props.total_quantity + ' Fles' : $props.total_quantity + ' Flessen',
        design: "cart"
      }, {
        content: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cart, (cartItem) => {
            return (_openBlock(), _createElementBlock("div", {
              key: cartItem.item.id,
              class: "d-flex flex-row w-100 border-top py-3"
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(cartItem.quantity) + "x ", 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h4", null, _toDisplayString(cartItem.item.name), 1),
                _createElementVNode("p", null, [
                  (cartItem.item.type === 'wine')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(cartItem.item.year_of_harvest !== '0' ? cartItem.item.year_of_harvest : 'N.M.') + " | " + _toDisplayString(cartItem.item.volume) + "L ", 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(["me-1 circle", cartItem.item.inventory_color])
                  }, null, 2)
                ])
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["label"]),
      ($props.shipping_costs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("h5", null, "Verzendkosten", -1)),
            _createElementVNode("h5", _hoisted_7, [
              _createTextVNode(" €" + _toDisplayString($setup.money().getMoneyString($props.shipping_costs)) + " ", 1),
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex flex-row justify-content-between", $props.shipping_costs ? 'pt-2' : 'pt-3'])
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("h4", null, "Totaal", -1)),
        _createElementVNode("h3", _hoisted_8, [
          _createTextVNode(" €" + _toDisplayString($setup.money().getMoneyString($props.total)) + " ", 1),
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
        ])
      ], 2),
      ($props.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString($props.error), 1)
          ]))
        : _createCommentVNode("", true),
      ($props.activeTab.name === 'Winkelmand')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 2,
            class: "mt-4 d-block d-md-none",
            design: "primary",
            disabled: $props.cart && $props.cart.length === 0,
            text: "Verder naar bestellen",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('activate', 'Bestellen')))
          }, null, 8, ["disabled"]))
        : _createCommentVNode("", true),
      ($props.activeTab.name === 'Bestellen')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 3,
            class: "mt-4 d-block d-md-none",
            design: "red",
            disabled: !$props.accepted,
            text: "Plaats bestelling",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('order')))
          }, null, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ]),
    ($props.activeTab.name === 'Winkelmand')
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "d-none d-md-block float-md-end",
          design: "primary",
          text: "Verder naar bestellen",
          disabled: $props.cart && $props.cart.length === 0,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('activate', 'Bestellen')))
        }, null, 8, ["disabled"]))
      : _createCommentVNode("", true),
    ($props.activeTab.name === 'Bestellen')
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          class: "d-none d-md-block float-md-end",
          design: "red",
          text: "Plaats bestelling",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('order')))
        }))
      : _createCommentVNode("", true)
  ]))
}