
import PageFooter from '@/shared/components/PageFooter.vue';

export default {
    props: {
        title: {
            default: 'Heading',
            type: String,
            required: true,
        },
        footer: {
            type: String,
        },
    },
    components: {
        PageFooter,
    },
    setup(props: any, context: any) {
        function hasSlot(slotName: string) {
            return !!context.slots[slotName];
        }
        return {
            hasSlot,
        };
    },
};
