import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex flex-row justify-content-between align-items-start" }
const _hoisted_3 = { class: "text-start w-50" }
const _hoisted_4 = { class: "tabs w-50" }
const _hoisted_5 = { class: "d-flex flex-column flex-wrap flex-xl-nowrap flex-xl-row justify-content-between gap-2" }
const _hoisted_6 = { class: "main-content" }
const _hoisted_7 = {
  key: 0,
  class: "info-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString($props.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "tabs", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "main", {}, undefined, true)
      ]),
      ($setup.hasSlot('info'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "info", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    ($props.footer)
      ? (_openBlock(), _createBlock(_component_PageFooter, {
          key: 0,
          footer: $props.footer,
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('footerButtonClick')))
        }, null, 8, ["footer"]))
      : _createCommentVNode("", true)
  ]))
}